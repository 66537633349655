import type { MouseEventHandler, ChangeEventHandler } from 'react'
import cx from 'classnames'
import { useFormContext } from 'react-hook-form'
import castArray from 'lodash.castarray'

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import styles from './Switch.module.css'

type SwitchProps = {
  className?: string
  labelClassName?: string
  label?: string | React.ReactNode
  onClick?: MouseEventHandler<HTMLLabelElement> | undefined
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined
  disabled?: boolean
  checked?: boolean // Controlled: `checked` prop
  defaultChecked?: boolean // Uncontrolled: `defaultChecked` prop for initial state
  id?: string
  name: string
  firstChoiceLabel?: string
  secondChoiceLabel?: string
}

// This component can work in both a controlled and uncontrolled manner.
//
// This is because it's common to want to use a Switch outside of a standard
// form - in that case you would want to be able to control the state of the
// Switch directly.
//
// Passing a `checked` prop will make the Switch controlled, otherwise it will
// be uncontrolled.
//
// Integration with react-hook-form is also supported, but only works in an
// uncontrolled manner.
const Switch = ({
  className,
  labelClassName,
  label,
  onClick,
  onChange,
  disabled = false,
  checked, // Controlled
  defaultChecked, // Uncontrolled
  id,
  name,
  firstChoiceLabel,
  secondChoiceLabel
}: SwitchProps) => {
  const formContext = useFormContext()
  const register = formContext ? formContext.register : undefined
  const { errors: contextErrors = {} } = formContext?.formState || {}

  const contextError = contextErrors[name]?.message as string | undefined
  const errors = contextError ? castArray(contextError) : []

  // Create a custom onChange handler that ensures boolean values
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const booleanValue = event.target.checked

    // Call the form's registered onChange if it exists
    if (register) {
      const registeredOnChange = register(name).onChange
      // Ensure the event gets processed by react-hook-form
      registeredOnChange(event)

      // Update the form value explicitly to ensure boolean type
      formContext?.setValue(name, booleanValue)
    }

    // Call the component's onChange if provided
    onChange?.(event)
  }

  // Prepare the input attributes based on whether we're in controlled or uncontrolled mode
  const inputProps =
    checked !== undefined
      ? {
          // Controlled mode
          checked,
          onChange: handleChange,
          name
        }
      : {
          // Uncontrolled mode with react-hook-form integration
          defaultChecked,
          ...(register?.(name) || { name }),
          onChange: handleChange
        }

  return (
    <>
      <div className={cx(styles.switchContainer, { [styles.isDisabled ?? '']: disabled }, className)}>
        {label && (
          <label htmlFor={id || name} className={cx(styles.switchLabel, labelClassName)}>
            {label}
          </label>
        )}
        <div className={styles.switchWrapper}>
          <input type="checkbox" id={id || name || ''} disabled={disabled} {...inputProps} />
          {firstChoiceLabel && secondChoiceLabel ? (
            <label htmlFor={id || name} onClick={onClick} className={styles.textSwitch}>
              <span className={styles.firstChoiceLabel}>{firstChoiceLabel}</span>
              <span className={styles.secondChoiceLabel}>{secondChoiceLabel}</span>
            </label>
          ) : (
            <label htmlFor={id || name} onClick={onClick} className={styles.switch}>
              <CheckIcon className={styles.checkIcon} />
              <XMarkIcon className={styles.xMarkIcon} />
            </label>
          )}
        </div>
      </div>
      {errors.map((error, index) => (
        <span key={index} className="font-sm font-normal text-basePink">
          {error}
        </span>
      ))}
    </>
  )
}

export default Switch
